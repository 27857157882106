import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import { api } from "../services/api";

interface Company {
  id: string;
  name: string;
  genre: string;
  slug: string;
  logo_url: string;
  logo: string;
  color: string;
  privacy_policy_link?: string;
  secondary_color: string;
  has_create_client_independent: boolean;
  default_country: string;
}
interface Feature {
  facebook_pixel?: string;
  google_tag_manager?: string;
  google_analytics: string;
}
interface StoreClientProviderProps {
  children: ReactNode;
}

interface WhiteLabel {
  name: string;
  form_domain: string;
  logo_horizontal_url: string;
  logo_vertically_url: string;
  primary_color: string;
  secondary_color: string;
  favicon_url: string;
}

interface StoreClientContextProps {
  company: Company;
  replaceQuestion: string;
  whiteLabel?: WhiteLabel;
  feature: Feature;
  updateWhiteLabel: (whiteLabel: WhiteLabel) => void;
}
export const StoreClientContext = createContext<StoreClientContextProps>(
  {} as StoreClientContextProps
);

export function useStoreClient(): StoreClientContextProps {
  const context = useContext(StoreClientContext);

  if (!context) {
    throw new Error("useStoreClient must be used");
  }

  return context;
}
export function StoreClientProvider({ children }: StoreClientProviderProps) {
  const location = useLocation();
  const [company, setCompany] = useState<Company>({} as Company);
  const [whiteLabel, setWhiteLabel] = useState<WhiteLabel>();
  const [feature, setFeature] = useState<Feature>({} as Feature);

  const replaceQuestion = useMemo(() => {
    if (company) {
      if (company.genre === "F") {
        return `a ${company.name}`;
      } else {
        return `o ${company.name}`;
      }
    }
    return "";
  }, [company]);

  useEffect(() => {
    const [, , slug] = location.pathname.split("/");
    api
      .get(`/companies/slug/${slug}`)
      .then((response) => {
        setCompany(response.data.company);
        setFeature(response.data.feature);
      })
      .catch((err) => console.log(err));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateWhiteLabel = useCallback((whiteLabel: WhiteLabel) => {
    setWhiteLabel(whiteLabel);
  }, []);
  return (
    <StoreClientContext.Provider
      value={{
        company,
        replaceQuestion,
        updateWhiteLabel,
        whiteLabel,
        feature,
      }}
    >
      {children}
    </StoreClientContext.Provider>
  );
}
