/* eslint-disable jsx-a11y/alt-text */
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { ReactNode, useCallback, useState } from "react";
import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";
import { FaClock, FaTools } from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { SubmitButton } from "../../components/atoms/Button/SubmitButton";
import { TimerToast } from "../../components/molecules/TimerToast";
import { Footer } from "../../components/templates/Footer";
import { api } from "../../services/api";
import { useFormClient } from "./../../hooks/formClient";
interface AdminProps {
  children?: ReactNode;
}

export function Form({ children }: AdminProps) {
  const { search, company, isTest, feature, updateWhiteLabel, whiteLabel } =
    useFormClient();
  const [reload, setReload] = useState<boolean>(false);
  const [secondsAmount, setSecondsAmount] = useState(-1);
  const [logo, setLogo] = useState<string>("");

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const payment = params.get("payment");

    if (payment === "true") {
      setPaymentModalOpen(true);
    }
  }, []);

  useEffect(() => {
    if (feature.facebook_pixel) {
      ReactPixel.init(feature.facebook_pixel);
      ReactPixel.pageView();
    }
    if (feature.google_tag_manager) {
      TagManager.initialize({ gtmId: feature.google_tag_manager });
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          page: window.location.pathname,
        },
      });
    }

    if (feature.google_analytics) {
      ReactGA.initialize(feature.google_analytics, {
        gaOptions: { name: "Dynamic" },
      });
      ReactGA.ga(
        "Dynamic.send",
        "pageview",
        window.location.pathname + window.location.search
      );
    }
  }, [
    feature.facebook_pixel,
    feature.google_analytics,
    feature.google_tag_manager,
  ]);
  useEffect(() => {
    if (search.is_totem) {
      const idTimeOut = setTimeout(() => {
        if (secondsAmount > 0) {
          setSecondsAmount((state) => state - 1);
        }
      }, 1000);

      if (!window.location.pathname.endsWith("linktree")) {
        if (
          window.location.pathname ===
          `/formularios/${company.slug}/${search.slug}/obrigado`
        ) {
          if (secondsAmount > 10) {
            setSecondsAmount(10);
          } else if (secondsAmount === 0) {
            onClose();
            setReload(false);
            setSecondsAmount(-1);
            navigate(`/formularios/${company.slug}/${search.slug}/inicial`);
          }
        } else if (secondsAmount === 0) {
          onOpen();
          setReload(true);
          setSecondsAmount(-1);
          clearTimeout(secondsAmount);
        }
      } else {
        setReload(false);
        setSecondsAmount(-1);
      }

      return () => clearTimeout(idTimeOut);
    }
  }, [secondsAmount, onOpen, search, company.slug, navigate, reload, onClose]);

  useEffect(() => {
    if (feature.facebook_pixel) {
      ReactPixel.init(feature.facebook_pixel);
      ReactPixel.pageView();
    }
    if (feature.google_tag_manager) {
      TagManager.initialize({ gtmId: feature.google_tag_manager });
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          page: window.location.pathname,
        },
      });
    }
    if (feature.google_analytics) {
      ReactGA.initialize(feature.google_analytics);
      ReactGA.pageview(window.location.pathname);
    }
  }, [
    company,
    feature.facebook_pixel,
    feature.google_analytics,
    feature.google_tag_manager,
    search.id,
    search.unifyQrcodesSearches,
  ]);

  useEffect(() => {
    if (
      window.location.origin !== process.env.REACT_APP_WEB_URL &&
      window.location.origin !== process.env.REACT_APP_OTHER_WEB_URL
    ) {
      api
        .get(`/whiteLabels/formDomain?form_domain=${window.location.origin}`)
        .then((response) => {
          updateWhiteLabel(response.data);
        });
    }
  }, [updateWhiteLabel]);

  const reopenSearch = useCallback(() => {
    setSecondsAmount(search.time_reload_screen ?? 30);
    onClose();
    navigate(`/formularios/${company.slug}/${search.slug}/inicial`);
  }, [company, search, navigate, onClose]);

  useEffect(() => {
    if (company.logo) {
      api.get(`/companies/logo/${company.logo}`).then((response) => {
        setLogo(response.data);
      });
    }
  }, [company.logo]);

  const [isMobile] = useMediaQuery("(max-width: 700px)");

  return (
    <>
      {whiteLabel ? (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Pesquisa - {whiteLabel.name}</title>
          <link rel="icon" href={whiteLabel.favicon_url} />
          <link
            rel="manifest"
            href={`${process.env.REACT_APP_API_FORMULARIO_URL}/whiteLabels/formDomain/manifest?form_domain=${whiteLabel.form_domain}`}
          />
        </Helmet>
      ) : (
        <Helmet>
          <title>Falaê - Pesquisa</title>
          <link rel="icon" href="favicon.ico" />
          <link rel="apple-touch-icon" href="logo192.png" />
          <link rel="manifest" href="/manifest.json" />
        </Helmet>
      )}
      {isTest && (
        <Flex
          w="100%"
          bg="#ff9000"
          height="64px"
          alignItems="center"
          justify="center"
          position="fixed"
          paddingX={4}
          zIndex={2}
        >
          <Icon as={FaTools} mr="4" />
          <Flex flexDirection="column">
            <Text fontSize={14} fontWeight="bold">
              Link de Teste
            </Text>
            <Text fontSize={12}>
              Você está usando um link de teste, dessa forma não é possível
              realizar cadastro de respostas ou cliente
            </Text>
          </Flex>
        </Flex>
      )}
      <Flex
        flexDir="column"
        justify="space-between"
        minH="100vh"
        fontFamily="Mulish"
      >
        <Flex align="center" justify="center">
          <Flex
            h="120px"
            w="100vw"
            bg="white"
            borderRadius="0% 0% 50% 50%"
            boxShadow="0px 10px 12px rgba(0,0,0,0.05)"
            justify="center"
            align="center"
          >
            <Avatar
              src={logo}
              name={company.name}
              loading="lazy"
              size="xl"
              mx="auto"
              bg="transparent"
              borderRadius="50%"
            />
          </Flex>
        </Flex>

        <Flex
          maxW="700px"
          my="5px"
          minH="100%"
          px={{ base: "25px", md: 0 }}
          mx="auto"
          minW={isMobile ? "90%" : "700px"}
        >
          <Stack spacing={5} flex="1" direction="column">
            {children}
          </Stack>
        </Flex>
        <Footer whiteLabel={whiteLabel} />
      </Flex>
      <TimerToast
        color={company.secondary_color}
        show={secondsAmount >= 0 && secondsAmount < 10 && search.is_totem}
        message={`Tempo para reiniciar formulário: ${secondsAmount}s`}
      />
      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent marginX={5}>
          <ModalHeader
            alignItems="center"
            flexDirection="column"
            textAlign="center"
          >
            <Icon as={FaClock} fontSize={32} />
            <Text>Tempo Esgotado</Text>
          </ModalHeader>

          <ModalBody>
            <Text textAlign="center">
              O tempo de inatividade foi atingido. Reinicie o formulário para
              utilizá-lo novamente.
            </Text>
          </ModalBody>

          <ModalFooter justifyContent="center">
            <Flex
              as="form"
              id="form-inactivity"
              display="none"
              onSubmit={(e: any) => {
                e.preventDefault();
                reopenSearch();
              }}
            ></Flex>
            <SubmitButton
              bg={company.secondary_color}
              color="white"
              text="Reiniciar"
              form="form-inactivity"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={isPaymentModalOpen}
        onClose={() => setPaymentModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent mx={4} p={4} textAlign="center">
          <ModalHeader
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Icon as={FiCheckCircle} color="green.500" boxSize={12} mb={4} />
            <Text fontSize="lg" fontWeight="bold">
              Pagamento Concluído!
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="md" mb={4}>
              Seu pagamento foi realizado com sucesso. Obrigado!
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button
              fontWeight="normal"
              color="#fff"
              bg={company.secondary_color}
              _hover={{
                bg: company.secondary_color,
                filter: "brightness(0.8)",
              }}
              size="md"
              onClick={() => setPaymentModalOpen(false)}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
