import { useFormClient } from "../../../hooks/formClient";

import { useCallback, useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { api } from "../../../services/api";
import { SearchOnePage } from "../SearchOnePage";
import { SearchMultiPage } from "../SearchMultiPage";
import { AxiosError } from "axios";
interface Question {
  id: string;
  name: string;
  nick: string;
  required: boolean;
  others?: boolean;
  unknown?: boolean;
  description: string;
  type_csat?: "numerico" | "satisfeito";
  type:
    | "NPS"
    | "CSAT"
    | "Caixa de seleção"
    | "Multipla escolha"
    | "Rating"
    | "Like/Dislike"
    | "Resposta curta";
  options: {
    id: string;
    name: string;
    created_at: Date;
  }[];
  detractor_replica?: string;
  neutral_replica?: string;
  promoter_replica?: string;
  format: "default" | "select";
}

type AnswerFormData = {
  nps: number;
  discursive_question?: string;
  quizResponses: [string | number];
  quizResponses_suggestion: [string | ""];
};
interface SettingPage {
  page_id?: string;
  client_id?: string;
  consumption_id?: string;
}

export function IndexSearchControl() {
  const { first_id, second_id, third_id } = useParams();
  const [settingPage, setSettingPage] = useState<SettingPage>(
    {} as SettingPage
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const {
    search,
    company,
    isTest,
    client,
    dispatch,
    setTypeAnswer,
    consumption,
  } = useFormClient();
  const [responseChannel, setResponseChannel] = useState<string>("");

  const handleCreateAnswer: SubmitHandler<AnswerFormData> = useCallback(
    async (formValue) => {
      try {
        const search_invoice_id =
          localStorage.getItem(`degust-${search.id}`) ?? "";
        if (!isTest) {
          const response = await api.post("/clientForms/answer", {
            search_id: search.id,
            company_id: search.company_id,
            dispatch_id: dispatch,
            nps: Number(formValue.nps),
            discursive_question: formValue.discursive_question,
            quizResponses: formValue.quizResponses,
            page_id: settingPage.page_id,
            client_id: settingPage.client_id,
            quizResponses_suggestion: formValue.quizResponses_suggestion,
            is_enps: search.is_enps,
            response_channel: responseChannel,
            consumption_id: settingPage.consumption_id,
            search_invoice_id,
          });
          localStorage.setItem(
            `@falae:${search.id}`,
            new Date().getTime().toString()
          );
          localStorage.removeItem(`degust-${search.id}`);
          const type =
            Number(formValue.nps) > 8
              ? "promoter"
              : Number(formValue.nps) > 6
              ? "neutral"
              : "detractor";
          setTypeAnswer(type);
          if (search.is_enps || search.no_register) {
            navigate(`/formularios/${company.slug}/${search.slug}/obrigado`);
          } else {
            if (response.data.client) {
              if (response.data.client.completed) {
                if (response.data.client.coupons.length > 0) {
                  navigate(
                    `/formularios/${company.slug}/${search.slug}/obrigado/${response.data.client.coupons[0].id}`
                  );
                } else {
                  navigate(
                    `/formularios/${company.slug}/${search.slug}/obrigado`
                  );
                }
              } else {
                navigate(
                  `/formularios/${company.slug}/${search.slug}/cliente/completar/${response.data.client.id}`
                );
              }
            } else {
              if (search.reverse_order) {
                navigate(
                  `/formularios/${company.slug}/${search.slug}/obrigado`
                );
              } else {
                if (search.store_client_with_qrcode) {
                  navigate(
                    `/formularios/${company.slug}/${search.slug}/cliente/qrcode/inicial/${response.data.answer.id}`
                  );
                } else {
                  if (search.skip_registration_decision) {
                    navigate(
                      `/formularios/${company.slug}/${search.slug}/cliente/atualizar/${response.data.answer.id}`
                    );
                  } else {
                    navigate(
                      `/formularios/${company.slug}/${search.slug}/cliente/inicial/${response.data.answer.id}`
                    );
                  }
                }
              }
            }
          }
        }
      } catch (err) {}
    },
    [
      isTest,
      search.id,
      search.company_id,
      search.is_enps,
      search.slug,
      search.reverse_order,
      search.store_client_with_qrcode,
      search.skip_registration_decision,
      settingPage.page_id,
      settingPage.client_id,
      settingPage.consumption_id,
      search.no_register,
      responseChannel,
      navigate,
      company.slug,
      dispatch,
      setTypeAnswer,
    ]
  );

  useEffect(() => {
    const page = search.has_page ? (first_id ? first_id : "") : "";
    const response = localStorage.getItem(`@B2S:link`);
    const checkIfClientAnswers = localStorage.getItem(`@falae:${search.id}`);

    if (checkIfClientAnswers && !search.is_totem) {
      const today = new Date().getTime();

      const diffInMs = Math.abs(today - Number(checkIfClientAnswers));

      const diffInMin = diffInMs / 60000;

      if (diffInMin < search.min_to_next_answer) {
        navigate(`/formularios/${company.slug}/${search.slug}/obrigado`);
      }
    }

    if (response) {
      setResponseChannel("QRcode");
    } else {
      setResponseChannel("Link");
    }

    if (search.has_page) {
      const client_id = client ? client : second_id;
      if (search.unique_answer_client && client_id) {
        api
          .get(`/clientForms/checkAnswer/${client_id}/${search.id}`)
          .then((response) => {
            if (response.data.total > 0) {
              navigate(`/formularios/${company.slug}/${search.slug}/obrigado`);
            }
          });
      }
      if (first_id) {
        const consumption_id = consumption ? consumption : third_id;
        setSettingPage({
          page_id: first_id,
          client_id,
          ...(consumption_id ? { consumption_id } : ""),
        });
      } else {
        navigate(`/formularios/${company.slug}/${search.slug}/pagina/inicial`);
      }
    } else {
      const client_id = client ? client : first_id;

      if (search.unique_answer_client && client_id) {
        api
          .get(`/clientForms/checkAnswer/${client_id}/${search.id}`)
          .then((response) => {
            if (response.data.total > 0) {
              navigate(`/formularios/${company.slug}/${search.slug}/obrigado`);
            }
          });
      }
      if (!first_id && search.reverse_order && search.anonymous_search) {
        navigate(`/formularios/${company.slug}/${search.slug}/cliente/inicial`);
      }

      const consumption_id = consumption ? consumption : second_id;

      setSettingPage({
        client_id,
        ...(consumption_id ? { consumption_id } : ""),
      });
    }
    if (search.id) {
      api
        .get(`/clientForms/question/${search.id}/${page}`)
        .then((response) => {
          setQuestions(response.data.questions);
          setLoading(true);
        })
        .catch((err: AxiosError) => {
          if (err) {
            if (err.response) {
              if (err.response.status === 404) {
                navigate(
                  `/formularios/${company.slug}/${search.slug}/pagina/inicial`
                );
              }
            }
          }
          console.log(err);
        });
    }
    if (search.id !== undefined && !search.active) {
      navigate(`/formularios/${company.slug}/${search.slug}/desativada`);
    }
  }, [
    search,
    first_id,
    second_id,
    navigate,
    company.slug,
    third_id,
    client,
    company,
    consumption,
  ]);

  switch (search.type_form) {
    case "onepage":
      return (
        <SearchOnePage
          handle={handleCreateAnswer}
          loading={loading}
          questions={questions}
        />
      );
    case "multipages":
      return (
        <SearchMultiPage
          handle={handleCreateAnswer}
          loading={loading}
          questions={questions}
        />
      );

    default:
      return (
        <SearchOnePage
          handle={handleCreateAnswer}
          loading={loading}
          questions={questions}
        />
      );
  }
}
