import { Flex, Checkbox, CheckboxGroup, Stack, Input } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { Form } from "../../../components/atoms/Form";
import { PrivacityText } from "../../../components/atoms/Text/PrivacityText";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { useFormClient } from "../../../hooks/formClient";
import { TitleDefault } from "../../../components/atoms/Text/TitleDefault";
import { SubtitleDefault } from "../../../components/atoms/Text/SubtitleDefault";
import { CompleteClientForm } from "../../../components/templates/CompleteClient";
import { HTMLText } from "../../../components/atoms/Div/HTMLText";

interface IProps {
  handle: (data: any) => void;
  form: UseFormReturn<any>;
}

export function CompleteClientOnePage({ handle, form }: IProps) {
  const { search, company } = useFormClient();
  const { register, handleSubmit, formState } = form;

  const { errors } = formState;

  return (
    <>
      <TitleDefault title="Complete o seu cadastro" />
      <SubtitleDefault subtitle={company.name} />
      <HTMLText html={search.client_message} />
      <Stack spacing={2}>
        <Form id="complete-client" onSubmit={handleSubmit(handle)}>
          <CompleteClientForm
            bg={company.color}
            register={register}
            errors={errors}
            has_cnpj={search.has_cnpj ?? false}
            default_country={company.default_country}
          />

          <CheckboxGroup>
            <Flex alignItems="start" justify="start" flexDir="column">
              <Checkbox {...register("approved")} isRequired={true} variant="">
                <PrivacityText link={company.privacy_policy_link} />
              </Checkbox>
            </Flex>
            <Input required={true} type="hidden" />
          </CheckboxGroup>
        </Form>
      </Stack>

      <Flex justify="flex-end" alignItems="center">
        <SubmitButton
          bg={company.color}
          color="white"
          text="Cadastrar"
          form="complete-client"
          isLoading={formState.isSubmitting}
        />
      </Flex>
    </>
  );
}
